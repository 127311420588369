import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Navbar from './components/Navbar';
import AdminLayout from './pages/sections/admin/AdminLayout';
// import UserTableData from './components/Tables/User/UserTableData';
import StylesTable from './components/Tables/Styles/StylesTable';
import BookingsTable from './components/Tables/Booking/BookingTable';
import UploadData from './components/Tables/Uploads/UploadData';
import VideoUploads from './components/forms/VideoUploads';
import QuestionUploads from './components/forms/QuestionUploads';
import ServiceInfo from './components/forms/ServiceInfo';
import Services from './pages/Services';
import { Toaster } from 'react-hot-toast';
import UpdateServiceInfo from './components/forms/UpdateServiceInfo';
import BookingDetails from './components/Bookings/BookingDetails';
import Policy from './pages/Policy';
import ProtectedRoute from './ProtectedRoutes';
import DashboardLayout from './pages/userdashboard/DashboardLayout';
import UserBookings from './pages/userdashboard/UserBookings';
import UserSettings from './pages/userdashboard/UserSettings';
import UserProfile from './pages/userdashboard/UserProfile';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store/store';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';

import UpdateServiceInfoWrapper from './components/Tables/Booking/UpdateServiceInfoWrapper';
import AddReviews from './components/Tables/reviews/AddReviews';
import UpdateBookingStatus from './components/Tables/Booking/UpdateBookingStatus';
import UpdateBookingStatusWrapper from './components/Tables/Booking/UpdateBookingStatusWrapper';
import Users from './components/Tables/users/Users';
import FAQPage from './pages/FAQPage';

const LayoutWithNavbar = () => (
  <div className="pt-16">
    <Navbar />
    <Outlet />
  </div>
);

const App = () => {
  const getrole = useSelector((state: RootState) => state.user.userData?.roles);

  const isAuthenticated = () => {
    return getrole && getrole.length > 0;
  };

  const isAdmin = () => {
    return getrole && getrole.includes('ROLE_ADMIN' as never);
  };

  const isUser = () => {
    return getrole && getrole.includes('ROLE_USER' as never
    );
  }

  const userIsAuthenticated = isAuthenticated();
  const userIsAdmin = isAdmin();
 const userIsUser = isUser();
    
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<LayoutWithNavbar />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/bookingdetails" element={<BookingDetails />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
          </Route>

          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Navigate to="bookings" />} />
            <Route path="bookings" element={<UserBookings />} />

            <Route path="settings" element={<UserSettings />} />
            <Route path="profile" element={<UserProfile />} />
          </Route>

          <Route element={<ProtectedRoute isAllowed={userIsAuthenticated} />}>
            <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<Navigate replace to="services" />} />
              {userIsAdmin && (
                <>
                  <Route path="services" element={<StylesTable />} />
                  <Route path="uploads" element={<UploadData />} />
                  <Route path="bookings" element={<BookingsTable />} />
                  <Route path="users" element={<Users />} />
                  <Route
                    path="/admin/update-booking/:id"
                    element={<UpdateBookingStatusWrapper />}
                  />
                  <Route path="video" element={<VideoUploads />} />
                  <Route path="quizes" element={<QuestionUploads />} />
                  <Route path="create-service" element={<ServiceInfo />} />
                  <Route path='reviews' element={<AddReviews />} />
                  <Route
                    path="update-service/:id"
                    element={<UpdateServiceInfoWrapper />}
                  />
                </>
              )}
              <Route path="*" element={<h1>Not Found</h1>} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster
        // position="middle-center"
        gutter={12}
        containerStyle={{
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        toastOptions={{
          success: {
            duration: 3000,
          },
          error: {
            duration: 5000,
          },
          style: {
            fontSize: '16px',
            maxWidth: '500px',
            padding: '16px 24px',
            backgroundColor: '#f0f0f0',
            color: '#333',
          },
        }}
      />
    </>
  );
};

export default App;
