

import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const FAQ = () => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    // Custom theme to match your color scheme
    const theme = createTheme({
        palette: {
            primary: {
                main: '#ff0066',
            },
        },
        typography: {
            fontFamily: '"Playfair Display", sans-serif',
        },
    });

    const faqData = [
        {
            question: "What age group do you style hair for?",
            answer: "We specialize in hairstyling for young girls aged 5 to 12, creating beautiful and age-appropriate braids and cornrows."
        },
        {
            question: "How long does a typical styling session take?",
            answer: "The duration depends on the hairstyle chosen but generally ranges from 1 to 3 hours. An estimated time duration is included on each hair style description"
        },
        {
            question: "Do you offer hair care advice for children?",
            answer: "Absolutely! We provide tips and guidance on how to maintain healthy hair and prolong the life of the styles we create."
        },
        {
            question: "Are the products and techniques you use safe for children?",
            answer: "Yes, we prioritize gentle techniques and child-friendly, high-quality products to ensure a comfortable and safe experience."
        },
        {
            question: "What hairstyles do you offer?",
            answer: "We offer a variety of styles, including braids, cornrows, twists, and other protective hairstyles suitable for children."
        },
        {
            question: "Are the prices for the hairstyles fixed?",
            answer: "Prices vary depending on the style, hair length, and any additional services requested. We provide a clear estimate of each style during the booking process."
        },
        {
            question: "Can I request a hairstyle different from the ones displayed on the website?",
            answer: "Yes! We're happy to work with your ideas or inspirations. Share pictures or details of your desired style during the consultation, and we'll let you know if it's possible."
        },
        {
            question: "How can I book an appointment?",
            answer: "You can book an appointment easily through our website's booking page. We are a member only site which means you need to be a member to book an appointment."
        },
        {
            question: "What should we do to prepare for a hairstyling session?",
            answer: "Please ensure your child's hair is clean, detangled, and free of any heavy products. If needed, we can provide a wash and detangling service for a fee before styling."
        },
        {
            question: "Do you offer hair treatments alongside styling?",
            answer: "This service is coming soon"
        },
        {
            question: "Can parents stay during the appointment?",
            answer: "Parents are allowed to stay for the 1st 10 to 15 mins to ensure their child feels comfortable with the environment. Parents will be notified 15mins before the Hair session is complete for pick-up"
        },
        {
            question: "What is your cancellation policy?",
            answer: "We kindly ask for at least 24 hours' notice if you need to reschedule or cancel your appointment."
        }
    ];

    return (
        <ThemeProvider theme={theme}>
            <section className="min-h-screen flex items-center justify-center" >
       
                <div className="max-w-6xl mx-auto px-6 py-10">
                    <h1
                        className="text-4xl md:text-5xl mb-12 text-center font-bold"
                        style={{ color: '#ff0066', fontFamily: '"Playfair Display", sans-serif' }}
                    >
                        Frequently Asked Questions
                    </h1>

                    <div className="bg-[#fadadf] rounded-3xl shadow-xl p-8 md:p-12">
                        {faqData.map((faq, index) => (
                            <Accordion
                                key={index}
                                expanded={expanded === `panel${index}`}
                                onChange={handleChange(`panel${index}`)}
                                sx={{
                                    backgroundColor: '#fadadf',
                                    marginBottom: '8px',
                                    boxShadow: 'none',
                                    '&:before': {
                                        display: 'none',
                                    },
                                    '&.Mui-expanded': {
                                        margin: '0 0 8px 0',
                                    }
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon sx={{ color: '#ff0066' }} />}
                                    sx={{
                                        '&.Mui-expanded': {
                                            minHeight: '48px',
                                        },
                                        '.MuiAccordionSummary-content.Mui-expanded': {
                                            margin: '12px 0',
                                        }
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: '"Playfair Display", sans-serif',
                                            fontWeight: 600,
                                            color: expanded === `panel${index}` ? '#ff0066' : '#555555'
                                        }}
                                    >
                                        {faq.question}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography
                                        sx={{
                                            fontFamily: '"Playfair Display", sans-serif',
                                            color: '#4a5568'
                                        }}
                                    >
                                        {faq.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </div>
            </section>
        </ThemeProvider>
    );
};

export default FAQ;