import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaUserCircle,
} from "react-icons/fa";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo-png.png";
import Modal from "./modal/Modal";
import Register from "./modal/Register";
import Login from "./modal/Login";
import ProfileMenu from "./ProfileMenu";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import { clearUser, setUser } from "../redux/reducers/userSlice";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.user.userData);
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  const toggleRegisterModal = () => {
    setShowRegisterModal(!showRegisterModal);
  };

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
  };

  const handleLoginSuccess = (userData: any) => {
    dispatch(setUser(userData));
    localStorage.setItem('userData', JSON.stringify(userData));
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    dispatch(clearUser());
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      dispatch(setUser(JSON.parse(storedUserData)));
    }
  }, [dispatch]);


  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate('/');
  }


  return (
    <nav className="py-3 px-4 md:px-8 flex justify-between items-center">
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="h-16 md:h-20 cursor-pointer" onClick={handleNavigate} />
      </div>

      <div className="md:hidden flex justify-end items-end">
        <HiMenuAlt3 onClick={toggleMenu} size={32} />
      </div>

      <ul className="hidden md:flex justify-center space-x-2 lg:space-x-6 text-lg lg:text-2xl font-semibold">
        <li>
          <Link to="/" className="text-[#A020F0] px-1 hover:text-[#FF0066] transition duration-300 ease-in-out">Home</Link>
        </li>
        <li>
          <Link to="/services" className="text-[#A020F0] px-1 hover:text-[#FF0066] transition duration-300 ease-in-out">Services</Link>
        </li>
        <li>
          <Link to="/about" className="text-[#A020F0] px-1 hover:text-[#FF0066] transition duration-300 ease-in-out">About</Link>
        </li>
        <li>
          <Link to="/policy" className="text-[#A020F0] px-1 hover:text-[#FF0066] transition duration-300 ease-in-out">Policy</Link>
        </li>
        <li>
          <Link to="/faq" className="text-[#A020F0] px-1 hover:text-[#FF0066] transition duration-300 ease-in-out">FAQ</Link>
        </li>
      </ul>

      <div className="hidden md:flex items-center space-x-2 lg:space-x-4">
        <div className="flex items-center space-x-2 lg:space-x-4">
          <FaFacebook className="text-white cursor-pointer hover:text-[#FF0066] transition duration-300 ease-in-out" size={24} />
          <a href='https://www.instagram.com/blhrt24?igsh=dTlvamQ4Y2NtZHd6&utm_source=qr' target='_blank'><FaInstagram className="text-white cursor-pointer hover:text-[#FF0066] transition duration-300 ease-in-out" size={24} /></a>
          <FaTwitter className="text-white cursor-pointer hover:text-[#FF0066] transition duration-300 ease-in-out" size={24} />
        </div>
        {userData?.email ? (
          <div className="relative">
            <div onClick={toggleProfileMenu} className="flex items-center cursor-pointer">
              <FaUserCircle className="text-gray-600 hover:text-[#FF0066] transition duration-300 ease-in-out" size={24} />
            </div>
            {showProfileMenu && <ProfileMenu onLogout={handleLogout} userData={userData} />}
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <button
              onClick={toggleLoginModal}
              className="text-white bg-[#A020F0] px-3 py-1 lg:px-6 lg:py-2 text-sm lg:text-base rounded cursor-pointer hover:bg-[#E20F7F] hover:text-[#FFFF00] transition duration-300 ease-in-out"
            >
              Login
            </button>
            <button
              onClick={toggleRegisterModal}
              className="text-[#FFFF00] bg-[#E20F7F] px-3 py-1 lg:px-6 lg:py-2 text-sm lg:text-base rounded cursor-pointer hover:bg-[#A020F0] hover:text-white transition duration-300 ease-in-out"
            >
              Register
            </button>
          </div>
        )}
      </div>

      {showMenu && (
        <div className="md:hidden fixed top-0 left-0 w-full h-full z-50 bg-gray-800 bg-opacity-95">
          <div className="flex justify-end p-4">
            <HiX onClick={closeMenu} size={32} className="text-white cursor-pointer" />
          </div>
          <ul className="flex flex-col items-center py-4 space-y-4">
            <li className="border-b border-gray-600 py-2 w-full text-center">
              <Link to="/" className="text-white text-xl" onClick={closeMenu}>Home</Link>
            </li>
            <li className="border-b border-gray-600 py-2 w-full text-center">
              <Link to="/services" className="text-white text-xl" onClick={closeMenu}>Services</Link>
            </li>
            <li className="border-b border-gray-600 py-2 w-full text-center">
              <Link to="/about" className="text-white text-xl" onClick={closeMenu}>About</Link>
            </li>
            <li className="border-b border-gray-600 py-2 w-full text-center">
              <Link to="/policy" className="text-white text-xl" onClick={closeMenu}>Policy</Link>
            </li>
            <li className="border-b border-gray-600 py-2 w-full text-center">
              <Link to="/faq" className="text-white text-xl" onClick={closeMenu}>FAQ</Link>
            </li>
            {userData?.email ? (
              <li className="border-b border-gray-600 py-2 w-full text-center">
                <button className="text-white text-xl" onClick={() => { handleLogout(); closeMenu(); }}>Logout</button>
              </li>
            ) : (
              <>
                <li className="py-2 w-full text-center">
                  <button className="text-white bg-[#A020F0] px-6 py-2 rounded cursor-pointer hover:bg-[#E20F7F] hover:text-[#FFFF00] transition duration-300 ease-in-out text-xl" onClick={() => { toggleLoginModal(); closeMenu(); }}>
                    Login
                  </button>
                </li>
                <li className="py-2 w-full text-center">
                  <button className="text-[#FFFF00] bg-[#E20F7F] px-6 py-2 rounded cursor-pointer hover:bg-[#A020F0] hover:text-white transition duration-300 ease-in-out text-xl" onClick={() => { toggleRegisterModal(); closeMenu(); }}>
                    Register
                  </button>
                </li>
              </>
            )}
            <div className="flex items-center justify-center space-x-6 py-4">
              <FaFacebook className="text-white cursor-pointer" size={24} />
              <FaInstagram className="text-white cursor-pointer" size={24} />
              <FaTwitter className="text-white cursor-pointer" size={24} />
            </div>
          </ul>
        </div>
      )}

      <Modal isOpen={showRegisterModal} onClose={toggleRegisterModal}>
        <Register onCloseModal={() => setShowRegisterModal(false)} onSwitchToLogin={() => { setShowRegisterModal(false); setShowLoginModal(true); }} />
      </Modal>

      <Modal isOpen={showLoginModal} onClose={toggleLoginModal}>
        <Login onCloseModal={toggleLoginModal} onLoginSuccess={handleLoginSuccess} onSwitchToRegister={() => { setShowLoginModal(false); setShowRegisterModal(true); }} />
      </Modal>
    </nav>
  );
}

export default Navbar;
