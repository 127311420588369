import ServiceTypeSideNav from '../components/ServiceTypeSideNav';

import ContactForm from './sections/contact/ContactForm';
import ContactUs from './ContactUs';
import Footer from '../components/Footer';
import ServiceCardListFilter from '../components/ServiceCardListFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store/store';
import Loading from '../components/resuseables/Loading';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { fetchStyles } from '../redux/reducers/stylesSlice';
import { fetchServiceTypes } from '../redux/reducers/serviceTypeSlice';
import { fetchServiceStyles } from '../redux/reducers/serviceTypeStylesSlice';
import ServiceCardList from '../components/ServiceCardList';

const Services = () => {
  const [selectedServiceId, setSelectedServiceId] = useState<number | null>(null);
  const dispatch: ThunkDispatch<RootState, void, any> = useDispatch();

  const { styles, status: stylesStatus } = useSelector((state: RootState) => state.styles);
  const { services: serviceTypes, status: serviceTypesStatus } = useSelector((state: RootState) => state.serviceTypes);
  const { serviceStyle, status: serviceStyleStatus } = useSelector((state: RootState) => state.serviceTypeStyles);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (stylesStatus === 'idle') dispatch(fetchStyles());
    if (serviceTypesStatus === 'idle') dispatch(fetchServiceTypes());
  }, [dispatch, stylesStatus, serviceTypesStatus]);

  useEffect(() => {
    if (selectedServiceId) {
      dispatch(fetchServiceStyles(selectedServiceId));
    }
  }, [dispatch, selectedServiceId]);

  const isLoading = stylesStatus === 'loading' || serviceTypesStatus === 'loading' || serviceStyleStatus === 'loading';
  const isError = stylesStatus === 'failed' || serviceTypesStatus === 'failed' || serviceStyleStatus === 'failed';

  if (isLoading || isError) {
    return (
      <div className="flex justify-center items-center h-screen bg-[#fadadf]">
        <div className="text-center">
          {isLoading ? (
            <Loading />
          ) : (
            <div className="text-xl text-red-600 font-semibold">
              Error loading services. Please try again later.
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#fadadf]">
      <div className="flex flex-col justify-center items-center">
        <ServiceTypeSideNav
          serviceTypes={serviceTypes}
          setSelectedServiceId={setSelectedServiceId}
        />
        <div className="flex justify-center  w-full">
          {selectedServiceId ? (
            <ServiceCardListFilter serviceStyle={serviceStyle} />
          ) : (
            <ServiceCardList styles={styles} />
          )}
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Services;
