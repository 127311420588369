
import MeetTeam from './MeetTeam'
import FAQ from './FAQ'
import bgImg from '../assets/svg/rabout2.svg'
import Footer from '../components/Footer';

const FAQPage = () => {
    return (
        <div className="relative min-h-screen w-full bg-[#fadadf]">
            {/* Background SVG */}
            <div className="absolute top-0 left-0 right-0 w-full" style={{ height: '100vh' }}>
                <svg
                    width="1900"
                    height="1063"
                    viewBox="0 0 1921 1473"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center'
                    }}
                >
                    <path d="M301.191 414.934C301.191 414.934 243.592 -71.4029 708.867 91.8146C1174.14 254.971 1016.08 697.525 839.908 543.088C663.733 388.652 1004.72 -122.923 1359.4 111.526C1714.02 345.913 1628.24 790.616 1374.14 820.214C1120.04 849.812 1534.47 303.174 1849.61 648.216C2164.74 993.257 1458.88 1605.6 1176.54 1373.36C894.19 1141.12 1122.31 775.265 1187.83 1000.93C1253.36 1226.54 1065.88 1444.53 559.957 1429.24C54.0313 1413.95 294.498 638.391 468.461 867.313C642.425 1096.23 345.403 1348.18 -8.11206 1076.52C-361.689 804.924 52.7416 258.287 301.191 414.934Z" fill="#FF6699" />
                    <path d="M-275.809 363.934C-275.809 363.934 -333.408 -122.403 131.867 40.8146C597.141 203.971 439.082 646.525 262.908 492.088C86.7333 337.652 427.721 -173.923 782.403 60.5261C1137.02 294.913 1051.24 739.616 797.141 769.214C543.042 798.812 957.472 252.174 1272.61 597.216C1587.74 942.257 881.881 1554.6 599.536 1322.36C317.19 1090.12 545.314 724.265 610.835 949.933C676.355 1175.54 488.882 1393.53 -17.0434 1378.24C-522.969 1362.95 -282.502 587.391 -108.539 816.313C65.4247 1045.23 -231.597 1297.18 -585.112 1025.52C-938.689 753.924 -524.258 207.287 -275.809 363.934Z" fill="#F4BAD5" />
                    <path d="M1032.19 457.934C1032.19 457.934 974.592 -28.4029 1439.87 134.815C1905.14 297.971 1747.08 740.525 1570.91 586.088C1394.73 431.652 1735.72 -79.9226 2090.4 154.526C2445.02 388.913 2359.24 833.616 2105.14 863.214C1851.04 892.812 2265.47 346.174 2580.61 691.216C2895.74 1036.26 2189.88 1648.6 1907.54 1416.36C1625.19 1184.12 1853.31 818.265 1918.83 1043.93C1984.36 1269.54 1796.88 1487.53 1290.96 1472.24C785.031 1456.95 1025.5 681.391 1199.46 910.313C1373.42 1139.23 1076.4 1391.18 722.888 1119.52C369.311 847.924 783.742 301.287 1032.19 457.934Z" fill="#F4BAD5" />
                </svg>
            </div>

            {/* Content */}
            <div className="relative z-10">
                <div className="container mx-auto py-16">
                    <div className="space-y-16">
                        <FAQ />
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default FAQPage;