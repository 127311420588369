import React from 'react';

const MeetTeam = () => {
    return (
        <section className="flex items-center justify-center w-full px-4 sm:px-6 md:px-0">
            <div className="w-full max-w-6xl">
                <h1
                    className="text-2xl sm:text-3xl md:text-4xl xl:text-5xl font-semibold text-[#FF0066] mb-4 md:mb-12 md:text-left text-center"
                    style={{ fontFamily: '"Playfair Display", sans-serif' }}
                >
                    Meet the Team
                </h1>

                <div
                    className="space-y-4 sm:space-y-6"
                    style={{ fontFamily: '"Playfair Display", sans-serif' }}
                >
                    <div className="prose max-w-none text-[#555555]">
                        <p className="text-base sm:text-lg md:text-xl xl:text-2xl leading-relaxed text-justify">
                            Hi, I'm Tosin, and I'm so excited to have you here! and to welcome you and your little ones to my hair making world. My love for braiding hair started before my teenage years when I would create beautiful looks for friends and family. Over the years, my passion for creating beautiful, and healthy hairstyles has only grown stronger.
                        </p>

                        <p className="text-base sm:text-lg md:text-xl xl:text-2xl leading-relaxed mt-4 sm:mt-6 text-justify">
                            This journey is all about making young girls feel confident and beautiful while rocking their natural hair and ensuring their hair stays healthy and well cared for.
                        </p>

                        <p className="text-base sm:text-lg md:text-xl xl:text-2xl leading-relaxed mt-4 sm:mt-6 text-justify">
                            This website is a dream come true—a place where I can share my passion and connect with amazing families like yours. I am glad you have chosen to join me on this journey, helping me turn my lifelong love for hair making into a thriving reality.
                        </p>

                        <p className="text-base sm:text-lg md:text-xl xl:text-2xl leading-relaxed mt-4 sm:mt-6 italic text-justify">
                            To parents thank you for trusting me to care for your child's crown. To the little girls, I can't wait to meet you, have fun, and create hairstyles you'll love!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MeetTeam;