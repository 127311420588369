import React from 'react';
import cup from '../../../assets/images/value.png';
import group from '../../../assets/svg/groupframe.svg';
import target from '../../../assets/svg/target.svg';


const AboutSectionTwo = () => {
    const sections = [
        {
            imgSrc: target,
            title: 'Mission Statement',
            description: "We bring smiles to young girls by crafting pretty braids and cornrows that celebrate their unique beauty, while teaching them how to care for their hair and embrace their individuality one braid at a time!"
        },
        {
            imgSrc: group,
            title: 'Target Audience',
            description: "We cater to young girls aged 5 to 12"
        },
        {
            imgSrc: cup,
            title: 'Core Values',
            description: "C – Creativity & Care\nWe combine artistry with gentle techniques to create beautiful, unique hairstyles while prioritizing the comfort and well-being of every child.\n\nE – Empowerment through Education\nWe inspire confidence and celebrate the natural beauty of young girls by teaching them and their parents about proper hair care, fostering a lifelong appreciation for their hair.\n\nI – Inclusivity\nWe honor diversity by offering hairstyles that reflect the unique culture, personality, and preferences of each client.\n\nP – Patience & Professionalism\nWe create a welcoming, relaxed environment, ensuring every styling session is enjoyable and carried out with excellence."
        }
    ];

    return (
        <section className="py-4 sm:py-6 px-4 md:px-16">
            <div className="max-w-6xl mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
                    {sections.map((section, index) => (
                        <div
                            key={index}
                            className="relative group min-h-[300px] sm:h-96 flex flex-col items-center justify-center md:hover:bg-[#F4BAD5] md:hover:shadow-lg md:hover:rounded-2xl"
                        >
                            {/* Default State - Hidden on Mobile */}
                            <div className="hidden md:flex md:flex-col md:items-center">
                                <img
                                    src={section.imgSrc}
                                    alt={section.title}
                                    className="w-16 sm:w-24 h-16 sm:h-24 object-contain mb-4 sm:mb-6"
                                />
                                <h3 className="text-2xl sm:text-3xl font-semibold text-pink-600 text-center px-4">
                                    {section.title}
                                </h3>
                            </div>

                            {/* Detailed State - Visible on Mobile, Hover on Desktop */}
                            <div
                                className="absolute inset-0 flex flex-col items-center bg-[#F4BAD5] justify-start rounded-2xl
                                md:opacity-0 md:invisible 
                                md:group-hover:opacity-100 md:group-hover:visible transition-all duration-300"
                            >
                                <div className="p-4 sm:p-8 w-full h-full flex flex-col items-center">
                                    <img
                                        src={section.imgSrc}
                                        alt={section.title}
                                        className="w-12 sm:w-16 h-12 sm:h-16 object-contain mb-2 sm:mb-4"
                                    />
                                    <h3 className="text-lg sm:text-xl font-semibold text-pink-600 mb-2 sm:mb-4 text-center">
                                        {section.title}
                                    </h3>
                                    <div className="overflow-y-auto flex-grow w-full scrollbar-thin scrollbar-thumb-pink-400 scrollbar-track-transparent">
                                        <p className="text-sm sm:text-base text-gray-600 whitespace-pre-line text-center px-2 sm:px-4">
                                            {section.description}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AboutSectionTwo;
